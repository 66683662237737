import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ServicesGrid from "../components/servicesGrid";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="A civil contracting company delivering a wide range of multi civil
          construction projects for local government agencies, commercial
          clients and private residential clients throughout the wider
          Wellington region."
      image={"/home-banner.jpg"}
    />

    <section className="banner bg-light">
      <StaticImage
        className="banner__image"
        src="../images/home-banner.jpg"
        alt="Sierra Delta team and machinery"
        loading="eager"
        objectPosition="50% 90%"
      />
      <div className="banner__content">
        <h1 className="sr-only">Sierra Delta Civil Ltd </h1>
        <p className="lead">
          A civil contracting company delivering a wide range of multi civil
          construction projects for local government agencies, commercial
          clients and private residential clients throughout the wider
          Wellington region.
        </p>
        <p className="mb-5 lead">
          At the core of our project delivery is a team of highly qualified
          civil staff that combine technical knowledge, specialist skills and
          practical experience to consistently deliver quality outcomes for our
          projects.
        </p>
        <Link to="/contact/" className="btn btn-primary">
          Get in touch
        </Link>
      </div>
    </section>

    <ServicesGrid />
  </Layout>
);

export default IndexPage;
